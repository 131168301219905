import { Injectable } from '@angular/core';
import { StorageRefService } from './storage-ref.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    DEVICE_ID = 'deviceId';
    TOKEN: string = 'TOKEN';
    EXPIRY_DATE: string = 'EXPIRY_DATE';
    AWS_COOKIES_ARRAY: string = 'awsCookieArray';
    UPLOADED_QUESTION_DATA: string = 'UPLOADED_QUESTION_DATA';
    USERNAME_DATA: string = 'USERNAME_DATA';
    SELECTED_QUESTION_DATA: string = 'SELECTED_QUESTION_DATA';
    RENDERING_TIMESTAMP: string = 'RENDERING_TIMESTAMP';
    EXAM_CATEGORY_OBJECT: string = 'EXAM_CATEGORY_OBJECT';
    BREADS_CRUMB: string = 'BREADS_CRUMB';
    SELECTED_LANGUAGE: string = 'SELECTED_LANGUAGE';
    EXPORT_PDF_DATA: string = 'EXPORT_PDF_DATA';
    BACK_URL: string = 'BACK_URL';
    ACTIVE_CONCEPT_TAGS_TAB: string = 'ACTIVE_CONCEPT_TAGS_TAB';
    EXAM_CATEGORY_DATA = 'EXAM_CATEGORY_DATA';
    IS_IFRAME_MODE_ENABLED = 'IS_IFRAME_MODE_ENABLED';
    IS_TEST_MODE_ACTIVE = 'IS_TEST_MODE_ACTIVE';
    QBG_USER_ROLE: string = 'QBG_USER_ROLE';
    DPP_SESSION_ID: string = 'DPP_SESSION_ID';
    ACTIVE_DPP_STATUS_TAB: string = 'ACTIVE_DPP_STATUS_TAB';
    WORD_TEMPLATE_DATA: string = 'WORD_TEMPLATE_DATA';
    INSTITUTE_ID: string = 'INSTITUTE_ID';
    SOURCE_APP: string = 'SOURCE_APP';
    ACADEMY_ADMIN: string = 'ACADEMY_ADMIN';
    PP_ADMIN: string = 'PP_ADMIN';
    ACADEMY_USER_ROLE_ID: string = 'ACADEMY_USER_ROLE_ID';
    QUESTION_IDS: string = 'QUESTION_IDS';
    DRIVE_TO_SECURE_EMAIL_DATA: string = 'DRIVE_TO_SECURE_EMAIL_DATA';

    private _localStorage: Storage;

    private _userToken$ = new BehaviorSubject<string>('');
    public userToken = this._userToken$.asObservable();

    private _uploadedQuestionData$ = new BehaviorSubject<string>('');
    public uploadedQuestionData = this._uploadedQuestionData$.asObservable();

    private _usernameData$ = new BehaviorSubject<string>('');
    public usernameData = this._usernameData$.asObservable();

    private _user$ = new BehaviorSubject<string>('');
    public user = this._user$.asObservable();

    private _expiryDate$ = new BehaviorSubject<number>(0);
    public expiryDate = this._expiryDate$.asObservable();

    private _selectedQuestionData$ = new BehaviorSubject<string>('');
    public selectedQuestionData = this._selectedQuestionData$.asObservable();

    constructor(private storageRefService: StorageRefService) {
        this._localStorage = this.storageRefService.getLocalStorage();
    }

    setRandomId(value: string) {
        this._localStorage.setItem('uuid', value);
    }

    getRandomId() {
        return this._localStorage.getItem('uuid');
    }

    clearAllLocalStorage() {
        this._localStorage.clear();
    }

    getAwsCookie() {
        return this._localStorage.getItem(this.AWS_COOKIES_ARRAY);
    }

    setAwsCookie(data: string) {
        return this._localStorage.setItem(this.AWS_COOKIES_ARRAY, data);
    }

    setUserToken(token: string) {
        this._localStorage.setItem(this.TOKEN, token);
        this._userToken$.next(token);
    }

    getUserToken() {
        return this._localStorage.getItem(this.TOKEN);
    }

    removeUserToken() {
        this._localStorage.removeItem(this.TOKEN);
        this._userToken$.next('');
    }

    setExpiryDate(expiryDate: number) {
        this._localStorage.setItem(this.EXPIRY_DATE, expiryDate.toString());
        this._expiryDate$.next(expiryDate);
    }

    getExpiryDate() {
        return this._localStorage.getItem(this.EXPIRY_DATE);
    }

    removeExpiryDate() {
        this._localStorage.removeItem(this.EXPIRY_DATE);
        this._expiryDate$.next(0);
    }

    setValue(key: string, data: any, type: 'string' | 'json') {
        if (type === 'json') {
            this._localStorage.setItem(key, JSON.stringify(data));
        } else {
            this._localStorage.setItem(key, data);
        }
    }

    getValue(key: string, type: 'string' | 'json') {
        if (type === 'json') {
            return JSON.parse(this._localStorage.getItem(key)!);
        }
        return this._localStorage.getItem(key);
    }

    setDeviceId(deviceId: string) {
        this._localStorage.setItem(this.DEVICE_ID, deviceId);
    }

    getDeviceId() {
        return this._localStorage.getItem(this.DEVICE_ID);
    }

    removeDeviceId() {
        this._localStorage.removeItem(this.DEVICE_ID);
    }

    removeValue(key: string) {
        this._localStorage.removeItem(key);
    }

    setUser(user: string) {
        this._localStorage.setItem('user', JSON.stringify(user));
        this._user$.next(user);
    }

    getUser() {
        return this._localStorage.getItem('user');
    }

    removeUser() {
        this._localStorage.removeItem('user');
        this._user$.next('');
    }

    /*Uploaded Question Data*/

    setUploadedQuestionData(value: any) {
        this._localStorage.setItem(
            this.UPLOADED_QUESTION_DATA,
            JSON.stringify(value)
        );
        this._uploadedQuestionData$.next(value);
    }

    getUploadedQuestionData() {
        const data = JSON.parse(
            <string>this._localStorage.getItem(this.UPLOADED_QUESTION_DATA)
        );
        this._uploadedQuestionData$.next(data);
        return this.uploadedQuestionData;
    }

    removeUploadedQuestionData() {
        this._localStorage.removeItem(this.UPLOADED_QUESTION_DATA);
        this._uploadedQuestionData$.next('');
    }

    /* Question Ids*/

    setQuestionIds(value: any) {
        this._localStorage.setItem(this.QUESTION_IDS, JSON.stringify(value));
    }

    getQuestionIds() {
        return JSON.parse(
            this._localStorage.getItem(this.QUESTION_IDS) || '[]'
        );
    }

    removeQuestionIds() {
        this._localStorage.removeItem(this.QUESTION_IDS);
    }

    /*Selected Question Data*/

    setSelectedQuestionData(value: any) {
        this._localStorage.setItem(
            this.SELECTED_QUESTION_DATA,
            JSON.stringify(value)
        );
        this._selectedQuestionData$.next(value);
    }

    getSelectedQuestionData() {
        const data = JSON.parse(
            <string>this._localStorage.getItem(this.SELECTED_QUESTION_DATA)
        );
        this._selectedQuestionData$.next(data);
        return this.selectedQuestionData;
    }

    removeSelectedQuestionData() {
        this._localStorage.removeItem(this.SELECTED_QUESTION_DATA);
        this._selectedQuestionData$.next('');
    }

    /*Username Data*/

    setUsername(value: any) {
        this._localStorage.setItem(this.USERNAME_DATA, JSON.stringify(value));
        this._usernameData$.next(value);
    }

    getUserName() {
        const data = JSON.parse(
            <string>this._localStorage.getItem(this.USERNAME_DATA)
        );
        this._usernameData$.next(data);
        return this.usernameData;
    }

    removeUserName() {
        this._localStorage.removeItem(this.USERNAME_DATA);
        this._usernameData$.next('');
    }

    /*MathEquationRendering*/

    setRenderingTime(timestamp: string) {
        this._localStorage.setItem(this.RENDERING_TIMESTAMP, timestamp);
    }

    getRenderingTime() {
        return this._localStorage.getItem(this.RENDERING_TIMESTAMP);
    }

    removeRenderingTime() {
        this._localStorage.removeItem(this.RENDERING_TIMESTAMP);
    }

    /* Exam category Object*/
    setExamCategory(value: string) {
        this._localStorage.setItem(this.EXAM_CATEGORY_OBJECT, value);
    }
    getExamCategory() {
        return this._localStorage.getItem(this.EXAM_CATEGORY_OBJECT);
    }

    removeExamCategory() {
        this._localStorage.removeItem(this.EXAM_CATEGORY_OBJECT);
    }

    setConceptTagsBreadsCrumb(data: any) {
        this._localStorage.setItem(this.BREADS_CRUMB, JSON.stringify(data));
    }

    getConceptTagsBreadsCrumb() {
        const data = JSON.parse(
            <string>this._localStorage.getItem(this.BREADS_CRUMB)
        );
        return data;
    }

    removeConceptTagsBreadsCrumb() {
        this._localStorage.removeItem(this.BREADS_CRUMB);
    }

    setSelectedLanguage(data: any) {
        this._localStorage.setItem(
            this.SELECTED_LANGUAGE,
            JSON.stringify(data)
        );
    }

    getSelectedLanguage() {
        const data = JSON.parse(
            <string>this._localStorage.getItem(this.SELECTED_LANGUAGE)
        );
        return data;
    }

    removeSelectedLanguage() {
        this._localStorage.removeItem(this.SELECTED_LANGUAGE);
    }

    setExportPdfData(data: any) {
        this._localStorage.setItem(this.EXPORT_PDF_DATA, data);
    }

    getExportPdfData() {
        return this._localStorage.getItem(this.EXPORT_PDF_DATA);
    }

    removeExportPdfData() {
        this._localStorage.removeItem(this.EXPORT_PDF_DATA);
    }

    setBackUrl(data: any) {
        this._localStorage.setItem(this.BACK_URL, data);
    }

    getBackUrl() {
        return this._localStorage.getItem(this.BACK_URL);
    }

    removeBackUrl() {
        this._localStorage.removeItem(this.BACK_URL);
    }

    setConceptTagActiveTab(data: any) {
        this._localStorage.setItem(this.ACTIVE_CONCEPT_TAGS_TAB, data);
    }

    getConceptTagActiveTab() {
        return this._localStorage.getItem(this.ACTIVE_CONCEPT_TAGS_TAB);
    }

    removeConceptTagActiveTab() {
        this._localStorage.removeItem(this.ACTIVE_CONCEPT_TAGS_TAB);
    }

    /*For PP-Admin source*/

    setAdminImportedData(data: any) {
        this._localStorage.setItem(
            this.EXAM_CATEGORY_DATA,
            JSON.stringify(data)
        );
    }

    getAdminImportedData() {
        return this._localStorage.getItem(this.EXAM_CATEGORY_DATA);
    }

    removeAdminImportedData() {
        this._localStorage.removeItem(this.EXAM_CATEGORY_DATA);
    }

    setIsIframeModeEnabled(data: any) {
        this._localStorage.setItem(this.IS_IFRAME_MODE_ENABLED, data);
    }

    getIsIframeModeEnabled() {
        return this._localStorage.getItem(this.IS_IFRAME_MODE_ENABLED);
    }

    removeIsIframeModeEnabled() {
        this._localStorage.removeItem(this.IS_IFRAME_MODE_ENABLED);
    }

    setIsTestModeActive(data: any) {
        this._localStorage.setItem(this.IS_TEST_MODE_ACTIVE, data);
    }

    getIsTestModeActive() {
        return this._localStorage.getItem(this.IS_TEST_MODE_ACTIVE);
    }

    removeIsTestModeActive() {
        this._localStorage.removeItem(this.IS_TEST_MODE_ACTIVE);
    }

    /*For PW-Academy source*/

    setInstituteID(data: any) {
        this._localStorage.setItem(this.INSTITUTE_ID, data);
    }

    getInstituteID() {
        return this._localStorage.getItem(this.INSTITUTE_ID);
    }

    removeInstituteID() {
        this._localStorage.removeItem(this.INSTITUTE_ID);
    }

    setSourceApp(data: any) {
        this._localStorage.setItem(this.SOURCE_APP, data);
    }

    getSourceApp() {
        return this._localStorage.getItem(this.SOURCE_APP);
    }

    removeSourceApp() {
        this._localStorage.removeItem(this.SOURCE_APP);
    }

    setAcademyUserRoleId(data: string) {
        this._localStorage.setItem(this.ACADEMY_USER_ROLE_ID, data);
    }

    getAcademyUserRoleId() {
        return this._localStorage.getItem(this.ACADEMY_USER_ROLE_ID);
    }

    removeAcademyUserRoleId() {
        this._localStorage.removeItem(this.ACADEMY_USER_ROLE_ID);
    }

    setQbgUserRole(data: any) {
        this._localStorage.setItem(this.QBG_USER_ROLE, JSON.stringify(data));
    }

    getQbgUserRole() {
        return this._localStorage.getItem(this.QBG_USER_ROLE);
    }

    setDppStatusActiveTab(data: any) {
        this._localStorage.setItem(this.ACTIVE_DPP_STATUS_TAB, data);
    }

    getDppStatusActiveTab() {
        return this._localStorage.getItem(this.ACTIVE_DPP_STATUS_TAB);
    }

    removeDppStatusActiveTab() {
        this._localStorage.removeItem(this.ACTIVE_DPP_STATUS_TAB);
    }

    setExportWordData(data: any) {
        this._localStorage.setItem(this.WORD_TEMPLATE_DATA, data);
    }

    getExportWordData() {
        return this._localStorage.getItem(this.WORD_TEMPLATE_DATA);
    }

    removeExportWordData() {
        this._localStorage.removeItem(this.WORD_TEMPLATE_DATA);
    }

    /* Drive To Secure Link Email Verified Data */
    setDriveToSecureEmailData(data: any) {
        this._localStorage.setItem(this.DRIVE_TO_SECURE_EMAIL_DATA, data);
    }

    getDriveToSecureEmailData() {
        return this._localStorage.getItem(this.DRIVE_TO_SECURE_EMAIL_DATA);
    }

    removeDriveToSecureEmailData() {
        this._localStorage.removeItem(this.DRIVE_TO_SECURE_EMAIL_DATA);
    }
}
