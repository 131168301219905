import { Injectable } from '@angular/core';
import { GlobalService } from '../global/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { StorageService } from '../storage/storage.service';
import { MatDialog } from '@angular/material/dialog';
import {
    ADMIN_ROLE_MAPPING,
    DPP_REDIRECTION,
    ROLE_MAPPING,
} from '../global-constant/global-constant.service';
import { HelperService } from '../../services/helper/helper.service';
import {
    ExamCategoryModel,
    LanguageTab,
} from '../listing-service/lisiting.modal';

@Injectable({
    providedIn: 'root',
})
export class AfterLoginService {
    isUserCameFromAcademyAdmin: boolean = false;
    examCategory: Array<ExamCategoryModel> = [];
    inputLanguage: Array<LanguageTab> = [];

    constructor(
        private _globalService: GlobalService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private storageService: StorageService,
        private dialogRef: MatDialog,
        private helperService: HelperService
    ) {}

    afterLoginProcess(res: any, adminToken?: any) {
        this.isUserCameFromAcademyAdmin =
            this.helperService.isUserCameFromAcademyAdmin();
        /*qbg accessed in iframe*/
        if (adminToken) {
            this._globalService.setAccessToken(adminToken);
            const checkAccess = this.checkAdminAccessRoleBased(
                res['data']['roles']
            );

            if (checkAccess) {
                /*assigning qbg_dc role to user accessing from outside having role(teacher/admin/Academy(SuperAdmin/Admin/Principal/Teacher))*/
                const role_changed_res = {
                    ...res,
                    data: {
                        ...res.data,
                        roles: [
                            ...res.data.roles,
                            {
                                id: '5b27bd915842f950a778c6eb',
                                name: 'Qbg dc',
                            },
                        ],
                    },
                };
                this._globalService.setUser(role_changed_res['data']);
                const userRole = this.getQbgUserRole(
                    role_changed_res['data']['roles']
                );
                this.storageService.setQbgUserRole(userRole);
            } else {
                this._globalService.showSnackBar('Access Denied', 'Ok');
                this.handleLogout();
            }
        } else {
            /*qbg accessed internally*/
            res['data'] = res;
            this._globalService.setAccessToken(res['data']['access_token']);

            const checkAccess = this.checkAccessRoleBased(
                res['data']['user']['roles']
            );

            if (checkAccess) {
                this._globalService.setUser(res['data']['user']);
                const userRole = this.getQbgUserRole(
                    res['data']['user']['roles']
                );
                this.storageService.setQbgUserRole(userRole);
                const redirectUrl =
                    this.activatedRoute.snapshot.queryParamMap.get('url') ||
                    'home';
                this.router.navigate([`${redirectUrl}`]);
            } else {
                this._globalService.showSnackBar('Access Denied', 'Ok');
                this.handleLogout();
            }
        }
    }

    checkAccessRoleBased(role: any) {
        const roleNames =
            role.map((ele: any) => ele?.name?.toString().toLowerCase()) || [];
        return (
            roleNames.includes(ROLE_MAPPING.QBG_ADMIN) ||
            roleNames.includes(ROLE_MAPPING.SME) ||
            roleNames.includes(ROLE_MAPPING.QC) ||
            roleNames.includes(ROLE_MAPPING.DEMAND) ||
            roleNames.includes(ROLE_MAPPING.DTP_FREELANCER) ||
            roleNames.includes(ROLE_MAPPING.GOOGLE_AUTH_QBG) ||
            roleNames.includes(ROLE_MAPPING.QBG_DC) ||
            roleNames.includes(ROLE_MAPPING.TAG_MANAGER) ||
            roleNames.includes(ROLE_MAPPING.QBG_SUB_ADMIN)
        );
    }

    checkAdminAccessRoleBased(role: any) {
        const roleNames =
            role.map((ele: any) => ele?.name?.toString().toLowerCase()) || [];
        return (
            roleNames.includes(ADMIN_ROLE_MAPPING.ADMIN) ||
            roleNames.includes(ADMIN_ROLE_MAPPING.TEACHER) ||
            roleNames.includes(ADMIN_ROLE_MAPPING.INSTITUTE_HEAD) ||
            roleNames.includes(ADMIN_ROLE_MAPPING.INSTITUTE_TEACHER) ||
            roleNames.includes(ADMIN_ROLE_MAPPING.SUPER_ADMIN) ||
            roleNames.includes(ADMIN_ROLE_MAPPING.ADMIN_ACADEMY)
        );
    }

    async handleLogout() {
        this._globalService.setAccessToken('');
        this._globalService.removeUser();
        this._globalService.authFailure();
        this.storageService.clearAllLocalStorage();
        this.dialogRef.closeAll();
        await this.router.navigate(['/auth'], {
            queryParams: {
                url: '',
            },
        });
    }

    getQbgUserRole(roles: any) {
        /*generating a reverse mapping object*/
        const REVERSE_ROLE_MAPPING: any = {};
        Object.keys(ROLE_MAPPING).forEach((key) => {
            const enumValue = ROLE_MAPPING[key as keyof typeof ROLE_MAPPING];
            REVERSE_ROLE_MAPPING[enumValue.toLowerCase()] = enumValue;
        });

        let userRole = {};

        for (const role of roles) {
            const roleName = role?.name?.toLowerCase();
            /*check if the role name exists in the role_mapping enum*/
            if (roleName && REVERSE_ROLE_MAPPING.hasOwnProperty(roleName)) {
                /*assign the first matching role from the enum to userRole*/
                userRole = role;
                break;
            }
        }

        return userRole;
    }
}
