import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subject } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { FormControl } from '@angular/forms';
declare let require: any;
const { v4: uuidv4 } = require('uuid');
declare var clearIndexedDB: any;

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    _isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    public _user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public _openEmailModal$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    public showSideNav$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    accessToken: string = '';
    _accessToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    programId!: string;
    _programId$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    filterData: any;

    constructor(
        private _storageService: StorageService,
        private _snackBar: MatSnackBar,
        private storage: StorageService
    ) {
        this.getUserToken();
    }

    setRandomId(value: string) {
        this._storageService.setRandomId(value);
    }

    getRandomId() {
        const idFromStorage = this._storageService.getRandomId() || '';
        if (idFromStorage.length === 0) {
            const randomId = uuidv4();
            this.setRandomId(randomId);
            return randomId;
        } else {
            return idFromStorage;
        }
    }

    authSuccessful() {
        this.setIsLoggedIn(true);
    }

    authFailure() {
        this.setIsLoggedIn(false);
    }

    setIsLoggedIn(value: boolean) {
        this._isLoggedIn$.next(value);
    }

    getIsLoggedIn() {
        return this._isLoggedIn$.asObservable();
    }

    getUserToken() {
        const token = this._storageService.getUserToken();

        if (token) {
            this.setAccessToken(token);
            this.authSuccessful();
            this.getUserFromStorage();
        } else {
            this.authFailure();
        }
    }

    setEmailModalOpen(value: boolean) {
        this._openEmailModal$.next(value);
    }

    getEmailValueOpen() {
        return this._openEmailModal$;
    }

    getUserFromStorage() {
        const user = this._storageService.getUser();
        if (typeof user === 'string') {
            this.setUser(JSON.parse(user));
        } else {
            this.setUser(user);
        }
    }

    setAccessToken(token: any) {
        this.authSuccessful();
        this.accessToken = token;
        this._accessToken$.next(token);
        this._storageService.setUserToken(token);
        return;
    }

    getAccessToken() {
        return this._accessToken$;
    }

    openSideNav(value: boolean) {
        this.showSideNav$.next(value);
    }

    isSideNavOpen() {
        return this.showSideNav$;
    }

    getAccessTokenFromStorage() {
        const token = this._storageService.getUserToken();
    }

    setUser(user: any) {
        if (user) {
            this._user$.next(user);
            return this._storageService.setUser(user);
        }
    }

    setProgram(programId: string) {
        if (programId.length > 0) {
            this.programId = programId;
            this._programId$.next(programId);
        }
    }

    getUser() {
        return this._user$;
    }

    removeUser() {
        this._user$.next(null);
        this.setEmailModalOpen(false);
        clearIndexedDB();
    }

    isAuth() {
        return this._accessToken$.value !== '';
    }

    showSnackBar(message: string, buttonText = 'Ok') {
        this._snackBar.open(message, buttonText, {
            duration: 5000,
            verticalPosition: 'top',
        });
    }

    clearStorage() {
        this.storage.clearAllLocalStorage();
    }

    /* video solution url validation */
    validateVideoInput(c: FormControl): any {
        const youtubeRegex =
            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

        const urlRegex =
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

        if (c?.value) {
            return youtubeRegex.test(c.value) || urlRegex.test(c.value)
                ? null
                : {
                      pattern: {
                          actualValue: c.value,
                          requiredPattern: '',
                      },
                  };
        } else {
            return null;
        }
    }
}
