import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('./pages/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'maintenance',
        loadChildren: () =>
            import('./pages/maintenance/maintenance.module').then(
                (m) => m.MaintenanceModule
            ),
    },
    {
        path: 'email-verification',
        loadChildren: () =>
            import('./pages/email-verification/email-verification.module').then(
                (m) => m.EmailVerificationModule
            ),
    },
    {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'create-question-paper',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import(
                './pages/create-question-paper/create-question-paper.module'
            ).then((m) => m.CreateQuestionPaperModule),
    },
    {
        path: 'saved-question-paper',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import(
                './pages/saved-question-paper/saved-question-paper.module'
            ).then((m) => m.SavedQuestionPaperModule),
    },
    {
        path: 'add-questions',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/add-questions/add-questions.module').then(
                (m) => m.AddQuestionsModule
            ),
    },
    {
        path: 'question-details',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/question/question.module').then(
                (m) => m.QuestionModule
            ),
    },
    {
        path: 'finalize-question-paper',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import(
                './pages/finalize-question-paper/finalize-question-paper.module'
            ).then((m) => m.FinalizeQuestionPaperModule),
    },
    {
        path: 'question-versions/:question_id',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/question-versions/question-versions.module').then(
                (m) => m.QuestionVersionsModule
            ),
    },
    {
        path: 'sample',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/dialogs-page/sample-page.module').then(
                (m) => m.SamplePageModule
            ),
    },
    {
        path: 'question-search',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/question-search/question-search.module').then(
                (m) => m.QuestionSearchModule
            ),
    },
    {
        path: 'reset-password',
        loadChildren: () =>
            import('./pages/auth/reset-password/reset-password.module').then(
                (m) => m.ResetPasswordModule
            ),
    },
    {
        path: 'forgot-password',
        loadChildren: () =>
            import('./pages/auth/forgot-password/forgot-password.module').then(
                (m) => m.ForgotPasswordModule
            ),
    },
    {
        path: 'otp-verify',
        loadChildren: () =>
            import('./pages/auth/otp-verify/otp-verify.module').then(
                (m) => m.OtpVerifyModule
            ),
    },
    /*{
        path: 'notification',
        loadChildren: () =>
            import('./pages/auth/notification/notification.module').then(
                (m) => m.NotificationModule
            ),
    },*/
    {
        path: 'concept-tags',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/concept-tags/concept-tags.module').then(
                (m) => m.ConceptTagsModule
            ),
    },
    /*{
        path: 'bulk-delete',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/bulk-delete/bulk-delete.module').then(
                (m) => m.BulkDeleteModule
            ),
    },*/
    {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
