import { Injectable } from '@angular/core';
import {
    PpHttpHeaders,
    PpHttpOptions,
    PpHttpParams,
    PpHttpService,
} from '@penpencil/common';
import { BehaviorSubject, catchError, map, retry } from 'rxjs';

import { handleError } from '../error-handler/error-handler.service';
import {
    CreateDppModal,
    FileDownloadRequest,
} from '../questions/question.model';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root',
})
export class QuestionPaperService {
    constructor(
        public apiService: PpHttpService,
        private storageService: StorageService
    ) {}
    _processedZipFileCount$: BehaviorSubject<any> = new BehaviorSubject({});

    reportQuestion(data: any) {
        const options = {
            apiPath: 'qbg/report-questions',
        };
        return this.apiService.post<any>(data, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    createQPDpp(body: any, basket_id: string, query?: any) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/export-basket`,
            params: query ? new PpHttpParams().appendAll(query) : undefined,
        };
        return this.apiService
            .post<CreateDppModal>(body, options)
            .pipe(catchError(handleError));
    }

    fileProcessed(request_id: string) {
        const options = {
            apiPath: `qbg/requests/${request_id}`,
        };
        return this.apiService.get<FileDownloadRequest>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getQuestionPaperStatusData(query?: any) {
        const userRoleId = this.storageService.getAcademyUserRoleId();
        const options: PpHttpOptions = {
            apiPath: 'qbg/basket',
            params: new PpHttpParams().appendAll(query),
            headers: userRoleId
                ? new PpHttpHeaders().append('academy-role-id', `${userRoleId}`)
                : new PpHttpHeaders(),
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res)
        );
    }

    deleteQuestionPaper(basket_id: string, userRoleId?: string, query?: any) {
        const options = {
            apiPath: `qbg/basket/${basket_id}`,
            params: new PpHttpParams().appendAll(query),
            headers: userRoleId
                ? new PpHttpHeaders().append('academy-role-id', `${userRoleId}`)
                : new PpHttpHeaders(),
        };
        return this.apiService.delete<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    recreateQuestionPaper(basket_id: string, body: any) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/share-basket`,
        };
        return this.apiService.post<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    createBasket(payload: any) {
        const options = {
            apiPath: 'qbg/basket',
        };
        return this.apiService.post<any>(payload, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getBasket(basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}`,
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    updateBasket(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}`,
        };
        return this.apiService.put<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    createBasketRule(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/rule`,
        };
        return this.apiService.post<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    updateBasketRule(body: any, rule_id: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/rule/${rule_id}`,
        };
        return this.apiService.put<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    deleteBasketRule(rule_id: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/rule/${rule_id}`,
        };
        return this.apiService.delete<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getBasketRule(rule_id: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/rule/${rule_id}`,
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    removeBasketRuleQuestion(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/remove-questions`,
        };
        return this.apiService.post<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    replaceBasketRuleQuestion(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/replace-question`,
        };
        return this.apiService.post<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getTextSearchBasketRule(basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/rule/text-search-rule`,
        };
        return this.apiService.get<any>(options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    resetBasket(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/reset-basket`,
        };
        return this.apiService.post<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    exportBasketExercise(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/qbg-pp/basket/${basket_id}/export-basket-exercise`,
        };
        return this.apiService
            .post<any>(body, options)
            .pipe(catchError(handleError));
    }

    exportBasketTest(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/export-basket-test`,
        };
        return this.apiService
            .post<any>(body, options)
            .pipe(catchError(handleError));
    }

    exportBasket(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/export-basket`,
        };
        return this.apiService
            .post<any>(body, options)
            .pipe(catchError(handleError));
    }
}
